
/* eslint-disable no-unused-vars */

import axios from 'axios';
import moment from 'moment';
import { CreateQueryParams } from '@dataui/crud-request';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import {
  AccountingConfiguration,
  AccountingConfigrationPresetType,
  Address,
} from '@gid/models';

import {
  AccountingConfigurationModule,
  AccountingConfigurationActionsEnum,
} from '../../store/accounting-configuration.module';

@Component({})
export default class CompanyConfiguration extends Vue {
  // Computed
  get store(): AccountingConfigurationModule {
    return getModule(AccountingConfigurationModule, this.$store);
  }

  get model() {
    if (this.store.allItems.length) {
      return this.store.allItems[0];
    }
    const m = new AccountingConfiguration();
    m.companyAddress = new Address();
    m.companyAddress.state = 'Berlin';
    m.companyAddress.stateCode = 'BE';
    m.configurationType = AccountingConfigrationPresetType.ACTIVE;
    m.companyAddress.countryCode = 'de';
    return m;
  }

  created() {
    this.store.pagination.sortBy = 'id';
    this.store.pagination.sortDir = true;
    this.refresh();
  }

  refresh() {
    const query: CreateQueryParams = {
      filter: [
        {
          field: 'configurationType',
          operator: '$eq',
          value: AccountingConfigrationPresetType.ACTIVE,
        },
      ],
      limit: 1,
    };
    this.store[AccountingConfigurationActionsEnum.ITEMS_FETCH]({
      query,
    });
  }

  onSave() {
    this.store[AccountingConfigurationActionsEnum.ITEMS_CREATE_ITEM]({
      data: {
        ...this.model,
        id: undefined as unknown as string,
      },
    });
  }

  onReset() {
    this.refresh();
  }
}
