
/* eslint-disable no-unused-vars */

import { Vue, Component, Ref } from 'vue-property-decorator';
import CrudListPagination from '@gid/vue-common/components/crud-list/CrudListPagination.vue';
import CrudListTable from '@gid/vue-common/components/crud-list/CrudListTable.vue';
import CrudListErrorMessage from '@gid/vue-common/components/crud-list/CrudListErrorMessage.vue';
import CrudEditModal from '@gid/vue-common/components/crud-list/CrudEditModal.vue';
import { AccountingBankAccountsModule } from '../../store/accounting-bank-accounts.module';
import { CreateQueryParams } from '@dataui/crud-request';
import { getModule } from 'vuex-module-decorators';

@Component({
  components: {
    CrudListTable,
    CrudListErrorMessage,
    CrudListPagination,
    CrudEditModal,
  },
})
export default class BankAccounts extends Vue {
  @Ref('table') readonly table;
  @Ref('upsertModal') readonly upsertModal;

  inProgress: boolean = false;

  created() {
    this.refreshDocumentList();
  }

  // Computed
  get store(): AccountingBankAccountsModule {
    return getModule(AccountingBankAccountsModule, this.$store);
  }
  get columns() {
    return [
      {
        key: 'id',
        sortable: true,
        label: 'Account',
      },
      {
        key: 'accountingNumber',
        sortable: true,
        label: 'Accounting Number',
      },
      {
        key: '_actions',
        label: 'Actions',
      },
    ];
  }

  createItem() {
    this.upsertModal.create();
  }

  editItem(item) {
    this.upsertModal.update(item.id);
  }

  cloneItem(item) {
    this.upsertModal.clone(item.id);
  }

  deleteItem(item) {
    this.upsertModal.delete(item.id);
  }

  refreshDocumentList(ctx?) {
    const query: CreateQueryParams = {
      page: this.store.pagination.currentPage,
      limit: this.store.pagination.perPage,
    };
    this.store.ITEMS_FETCH({
      query,
    });
  }
}
