
/* eslint-disable no-unused-vars */

import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import CompanyConfiguration from './CompanyConfiguration.vue';
import BankAccounts from './BankAccounts.vue';

@Component({
  components: {
    CompanyConfiguration,
    BankAccounts,
  },
})
export default class AccountingConfigView extends Vue {}
